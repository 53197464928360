<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div v-if="!item.id" class="p-dialog-title">{{ $t('Adding a new employee') }}</div>
      <div v-else class="p-dialog-title">{{ $t('Employee editing') }}</div>
    </template>

    <Spinner v-if="dataIsSending"></Spinner>
    <template v-else>
      <div class="p-formgrid p-grid p-pt-3">
        <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
          <div class="p-float-label p-input-icon-right">
            <i class="pi pi-user" />
            <InputText id="first_name" :class="{'p-invalid' : submitted && !userData.first_name}" v-model="userData.first_name" autocomplete="new-password"/>
            <label for="first_name">{{ $t('First name') }}<span class="warning-color">*</span></label>
          </div>
          <small class="p-invalid" v-if="submitted && !userData.first_name">{{ $t('Required field') }}</small>
        </div>

        <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
          <div class="p-float-label p-input-icon-right">
            <i class="pi pi-user" />
            <InputText id="last_name" v-model="userData.last_name" autocomplete="new-password"/>
            <label for="last_name">{{ $t('Last name') }}</label>
          </div>
        </div>

        <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
          <div class="phone-inputs-wrapper">
            <div class="p-float-label">
              <CustomDropdown id="phone_code"
                                  class="phone-code"
                                  :class="{'p-invalid' : submitted && (!selectedPhoneCode || phoneNumberIsTaken)}"
                                  v-model="selectedPhoneCode"
                                  @change="checkTakenPhoneNumberMatches"
                                  :clearSearchData="!visible"
                                  :options="phoneCodesArray"
                                  :filter="true"
                                  optionLabel="country"
                                  :filterFields="['country','value']"
                                  :showClear="false"
                                  autocomplete="new-password">
                <template #value="slotProps">
                  <div class="country-item country-item-value" v-if="slotProps.value">
                    <template v-if="slotProps.value.flagCode">
                      <span  :class="'flag--dropdown-value flag flag-' + slotProps.value.flagCode" />
                      <span>{{ slotProps.value.value }}</span>
                    </template>
                    <template v-else>
                      <span>{{ slotProps.value.value }}</span>
                    </template>
                  </div>
                  <span v-else style="visibility: hidden">.</span>
                </template>
                <template #option="slotProps">
                  <div class="p-d-flex p-ai-center p-jc-between">
                    <div>
                      <span v-if="slotProps.option.flagCode" :class="'flag--dropdown-option flag flag-' + slotProps.option.flagCode" />
                      <span>{{ slotProps.option.country }}</span>
                    </div>
                    <div style="font-weight: 500">{{ slotProps.option.value }}</div>
                  </div>
                </template>
              </CustomDropdown>
            </div>
            <div class="p-float-label p-input-icon-right">
              <i class="pi pi-phone" />
              <InputText
                  id="phone_number"
                  :class="{'p-invalid' : submitted && (!userData.phone_number || phoneNumberIsTaken)}"
                  class="phone-number"
                  v-model.trim="userData.phone_number"
                  @keypress="numberInputOnKeyPress"
                  @input="phoneNumberOnInput"
                  @blur="checkTakenPhoneNumberMatches"
                  @paste="numberOnPaste"
                  autocomplete="new-password"/>
  <!--            <InputNumber :locale="computedNumberInputLocale" v-model="userData.phone_number" :useGrouping="false" autocomplete="new-password"/>-->
              <label for="phone_number">{{ $t('Phone') }}<span class="warning-color">*</span></label>
            </div>
          </div>
          <small style="position: relative; left: 110px" class="p-invalid" v-if="submitted && !userData.phone_number">{{ $t('Required field') }}</small>
          <small class="p-invalid" v-else-if="submitted && userData.phone_number && phoneNumberIsTaken">{{ $t('Phone number has already been taken') }}</small>
        </div>

        <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
          <div class="p-float-label p-input-icon-right">
            <i class="ti-email" />
            <InputText id="user_email"
                       :class="{'p-invalid' : submitted && userData.email && !emailIsValid}"
                       @input="validateEmail"
                       @paste="validateEmail"
                       v-model="userData.email"
                       autocomplete="new-password"/>
            <label for="user_email">{{ $t('Email') }}</label>
          </div>
          <small class="p-invalid" v-if="submitted && userData.email && !emailIsValid">{{ $t('Value is not a valid email address') }}</small>
        </div>

        <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
          <div class="phone-inputs-wrapper">
            <div class="p-float-label">
              <CustomDropdown id="work_phone_code"
                                  class="phone-code"
                                  v-model="selectedWorkPhoneCode"
                                  :clearSearchData="!visible"
                                  :options="phoneCodesArray"
                                  :filter="true"
                                  optionLabel="country"
                                  :filterFields="['country','value']"
                                  :showClear="false"
                                  autocomplete="new-password">
                <template #value="slotProps">
                  <div class="country-item country-item-value" v-if="slotProps.value">
                    <template v-if="slotProps.value.flagCode">
                      <span  :class="'flag--dropdown-value flag flag-' + slotProps.value.flagCode" />
                      <span>{{ slotProps.value.value }}</span>
                    </template>
                    <template v-else>
                      <span>{{ slotProps.value.value }}</span>
                    </template>
                  </div>
                  <span v-else style="visibility: hidden">.</span>
                </template>
                <template #option="slotProps">
                  <div class="p-d-flex p-ai-center p-jc-between">
                    <div>
                      <span v-if="slotProps.option.flagCode" :class="'flag--dropdown-option flag flag-' + slotProps.option.flagCode" />
                      <span>{{ slotProps.option.country }}</span>
                    </div>
                    <div style="font-weight: 500">{{ slotProps.option.value }}</div>
                  </div>
                </template>
              </CustomDropdown>
            </div>
            <div class="p-float-label p-input-icon-right">
              <i class="pi pi-phone" />
  <!--            onkeypress="return event.charCode >= 48 && event.charCode <= 57"-->
              <InputText
                  id="work_phone_number"
                  class="phone-number"
                  v-model="employeeData.work_phone_number"
                  @keypress="numberInputOnKeyPress"
                  @paste="numberOnPaste"
                  autocomplete="new-password"/>
              <label for="work_phone_number">Work phone</label>
            </div>
          </div>
        </div>

        <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
          <div class="p-float-label">
            <CustomDropdown id="employee_language"
                                :class="{'p-invalid' : submitted && !selectedLanguage}"
                                v-model="selectedLanguage"
                                :options="languageValues"
                                :clearSearchData="!visible"
                                :filter="true"
                                optionLabel="label"
                                :showClear="false">
              <template #value="slotProps">
                <div class="country-item country-item-value" v-if="slotProps.value">
                  <span :class="'flag--dropdown-value flag flag-' + slotProps.value.flagCode" />
                  <span>{{slotProps.value.label}}</span>
                </div>
                <span v-else style="visibility: hidden">.</span>
              </template>
              <template #option="slotProps">
                <div>
                  <span :class="'flag--dropdown-option flag flag-' + slotProps.option.flagCode" />
                  <span>{{slotProps.option.label}}</span>
                </div>
              </template>
            </CustomDropdown>
            <label for="employee_language">{{ $t('Language') }}<span class="warning-color">*</span></label>
          </div>
          <small class="p-invalid" v-if="submitted && !selectedLanguage">{{ $t('Required field') }}</small>
        </div>



        <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
          <div class="p-float-label">
            <InputText id="personal_code" v-model="employeeData.personal_code" autocomplete="new-password"/>
            <label for="personal_code">{{ $t('Personal code') }}</label>
          </div>
        </div>

        <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
          <div class="p-float-label">
            <InputText id="contract" v-model="employeeData.contract" autocomplete="new-password"/>
            <label for="contract">{{ $t('Contract') }}</label>
          </div>
        </div>

        <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
          <div class="p-float-label">
  <!--          @input="checkContractStartDateIsValid"-->
  <!--          @blur="checkContractStartDateIsValid"-->
            <Calendar id="contract_start"
                      @show="toogleDropdownOverlayVisibility(true)"
                      @hide="toogleDropdownOverlayVisibility(false)"
                      @date-select="checkContractStartDateIsValid"
                      :class="{'p-invalid' : (employeeData.start && !contractStartDateIsValid) || (employeeData.start && employeeData.end && employeeData.start > employeeData.end)}"
                      v-model="employeeData.start"
                      :dateFormat="settings.dateFormat"
                      :showIcon="true"
                      :selectOtherMonths="true"
                      autocomplete="new-password"/>
            <label for="contract_start">{{ $t('Start') }}</label>
          </div>
          <small class="p-invalid" v-if="employeeData.start && !contractStartDateIsValid">{{ $t('Invalid date format') }}</small>
        </div>

        <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
          <div class="p-float-label">
            <Calendar id="contract_end"
                      @date-select="checkContractEndDateIsValid"
                      @show="toogleDropdownOverlayVisibility(true)"
                      @hide="toogleDropdownOverlayVisibility(false)"
                      :class="{'p-invalid' : (employeeData.end && !contractEndDateIsValid) || (employeeData.start && employeeData.end && employeeData.start > employeeData.end)}"
                      v-model="employeeData.end"
                      :dateFormat="settings.dateFormat"
                      :showIcon="true"
                      :selectOtherMonths="true"
                      autocomplete="new-password"/>
            <label for="contract_end">{{ $t('End') }}</label>
          </div>
          <small class="p-invalid" v-if="employeeData.end && !contractEndDateIsValid">{{ $t('Invalid date format') }}</small>
        </div>


        <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
          <div class="p-float-label">
            <CustomDropdown id="employee_role_modal"
                      :disabled="userData.role === constants.userRoles.superAdmin"
                      v-model="selectedRole"
                      :options="roleValues"
                      :clearSearchData="!visible"
                      optionLabel="name"
                      :class="{'p-invalid' : submitted && !selectedRole}"
                      :filter="true"
                      autocomplete="new-password"
                      :showClear="false">
              <template #value="slotProps">
                <div class="country-item country-item-value" v-if="slotProps.value">
                  <span>{{ slotProps.value.name }}</span>
                </div>
                <span v-else style="visibility: hidden">.</span>
              </template>
              <template #option="slotProps">
                <div>
                  <span>{{ slotProps.option.name }}</span>
                </div>
              </template>
            </CustomDropdown>
            <label for="employee_role_modal">{{ $t('Role') }}<span class="warning-color">*</span></label>
          </div>
          <small class="p-invalid" v-if="submitted && !selectedRole">{{ $t('Required field') }}</small>
        </div>

        <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
          <div class="p-float-label">
            <InputText id="employee_position_modal" v-model="employeeData.position" autocomplete="new-password"/>
            <label for="employee_position_modal">{{ $t('Position') }}</label>
          </div>
        </div>
        <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
          <div class="p-float-label">
            <Calendar
                id="employee-birthdate"
                @date-select="checkBirthDateIsValid"
                @show="toogleDropdownOverlayVisibility(true)"
                @hide="toogleDropdownOverlayVisibility(false)"
                :class="{'p-invalid' : userData.birthdate && !birthDateIsValid}"
                v-model="userData.birthdate"
                :dateFormat="settings.dateFormat"
                :showIcon="true"
                :monthNavigator="true"
                :yearNavigator="true"
                :yearRange="(new Date().getFullYear() - 80) + ':' + (new Date().getFullYear() - 14)"
                :selectOtherMonths="true"
                autocomplete="new-password"/>
            <label for="employee-birthdate">{{ $t('Birthdate') }}</label>
          </div>
          <small class="p-invalid" v-if="userData.birthdate && !birthDateIsValid">{{ $t('Invalid date format') }}</small>
        </div>

        <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
          <div class="p-float-label">
            <InputText id="user_username"
                       :class="submitted && ((savePassword && !userData.username) || usernameIsTaken) ? 'p-invalid' : ''"
                       v-model="userData.username"
                       @input="checkTakenUsernameMatches"
                       @blur="checkTakenUsernameMatches"
                       @paste="checkTakenUsernameMatches"
                       autocomplete="new-password"/>
            <label for="user_username">Username<span v-if="savePassword" class="warning-color">*</span></label>
          </div>
          <small class="p-invalid" v-if="submitted && savePassword && !userData.username">{{ $t('Required field') }}</small>
          <small class="p-invalid" v-else-if="submitted && userData.username && usernameIsTaken">{{ $t('Username has already been taken') }}</small>
          <small class="p-invalid" v-else-if="submitted && savePassword && (userData.username && userData.username.length < 7)">Too short</small>

        </div>
      </div>
      <div class="p-d-flex p-ai-center p-pt-1 p-pb-5">
        <div class="p-d-flex p-ai-center">
          <InputSwitch id="save_password" v-model="savePassword"/>
        </div>
        <label for="save_password" class="switch-label pointer">{{ $t('Save password') }}?</label>
      </div>
      <div class="p-formgrid p-grid" v-show="savePassword">
        <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
          <div class="p-float-label">
            <Password id="employee_password" :inputClass="savePassword && submitted && !userData.password ? 'p-invalid' : ''" v-model="userData.password" :feedback="false" toggleMask autocomplete="new-password"/>
            <label for="employee_password">{{ $t('Password') }}<span class="warning-color">*</span></label>
          </div>
          <small class="p-invalid" v-if="submitted && savePassword && !userData.password">{{ $t('Required field') }}</small>
          <small class="p-invalid" v-else-if="submitted && savePassword && (userData.password && userData.password.length < 7)">Too short</small>
          <small class="p-invalid" v-else-if="submitted && savePassword && userData.password_repeat !== userData.password">{{ $t('Passwords do not match') }}</small>
        </div>

        <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
          <div class="p-float-label">
            <Password id="employee_password_repeat" :inputClass="savePassword && submitted && !userData.password_repeat ? 'p-invalid' : ''" v-model="userData.password_repeat" :feedback="false" toggleMask autocomplete="new-password"/>
            <label for="employee_password_repeat">{{ $t('Repeat password') }}<span class="warning-color">*</span></label>
          </div>
          <small class="p-invalid" v-if="submitted && savePassword && !userData.password_repeat">{{ $t('Required field') }}</small>
          <small class="p-invalid" v-else-if="submitted && savePassword && userData.password_repeat !== userData.password">{{ $t('Passwords do not match') }}</small>
        </div>
      </div>

      <div class="p-formgrid p-grid">
        <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
          <LimitedCharsTextarea :submitted="submitted"
                                :rows="3"
                                v-model="employeeData.comment"
                                :maxChars="commentMaxChars"
                                :autocomplete="'new-password'"
                                :id="'employee-comment'"
                                :label="$t('Comment')"/>
        </div>
<!--        <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">-->
<!--          <AddressFieldset :modalLayer="modalLayer"-->
<!--                           :user="userData"-->
<!--                           @update-item="updateAddress"-->
<!--                           @update-items="updateItems"/>-->
<!--        </div>-->
      </div>

  <!--    <AddressModal-->
  <!--        :visible="addressModal"-->
  <!--        @close="closeAddressModal"-->
  <!--        :userId="userData.id"-->
  <!--        :item="userAddress"-->
  <!--        @change-address="changeAddress">-->
  <!--    </AddressModal>-->

    </template>
    <template #footer>
      <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-text" @click="close"/>
      <Button :label="$t('Save')" :disabled="disableSaveButton" icon="pi pi-check" class="p-button-text" @click="saveItem" />
    </template>
  </Modal>

<!--  <ConfirmDeleteModal-->
<!--      :visible="confirmDeleteAddressModal"-->
<!--      @close="closeConfirmDeleteAddressModal"-->
<!--      @delete-item="deleteAddress"-->
<!--      :data="deleteAddressData">-->
<!--  </ConfirmDeleteModal>-->
</template>

<script>
// import AddressModal from '@/pages/users/components/AddressModal'
import httpClient from '@/services/http.services'
import roles from '@/translations/roles'
import phoneCodes from '@/translations/phoneCodes'
import settings from '@/settings'
import constants from '@/constants'
// import AddressFieldset from "@/pages/users/components/AddressFieldset";
import httpMixins from "@/mixins/httpMixins";
import formatMixins from "@/mixins/formatMixins";
import overlayVisibilityMixins from "@/mixins/overlayVisibilityMixins";

export default {
  mixins: [ httpMixins, formatMixins, overlayVisibilityMixins ],
  // components: { AddressFieldset },
  emits: ['close', 'update-items', 'update-item'],
  name: 'EmployeeModal',
  props: {
    item: Object,
    visible: Boolean,
    modalLayer: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      commentMaxChars: 500,
      phoneNumberIsTaken: false,
      takenPhoneNumber: null,
      usernameIsTaken: false,
      takenUsername: false,
      contractStartDateIsValid: false,
      contractEndDateIsValid: false,
      birthDateIsValid: false,
      settings,
      phoneCodesArray: [],
      selectedPhoneCode: null,
      selectedWorkPhoneCode: null,
      submitted: false,
      emailIsValid: false,
      userData: {
        // address: {},
        // password: null,
        // password_repeat: null,
      },
      employeeData: {},
      // addressModal: false,
      // confirmDeleteAddressModal: false,
      addressDraft: null,
      userAddress: null,
      // originalItem: {},
      selectedLanguage: null,
      languageValues: settings.languages,
      savePassword: false,
      roleValues: null,
      selectedRole: null,
      constants,
      disableSaveButton: false,
      dataIsSending: false,
    }
  },
  watch: {
    item(value) {
      if (!value) return false
      // this.originalItem = { ...value }
      this.userData = { ...value }

      this.employeeData = value.employee ? { ...value.employee } : {}

      // this.userAddress = value?.address ?? {}
      if (value.email) {
        this.validateEmail()
      }

      if (value.employee) {
        this.employeeData.start = value.employee.start ? new Date(value.employee.start * 1000) : null
        this.employeeData.end = this.employeeData.end ? new Date(value.employee.end * 1000) : null
      }

      if (value.birthdate) {
        this.userData.birthdate = new Date(value.birthdate * 1000)
      }

      this.checkBirthDateIsValid()
      this.checkContractStartDateIsValid()
      this.checkContractEndDateIsValid()

      if (value.language) {
        this.selectedLanguage = settings.languages.find((item) => item.value === value.language)
      } else {
        this.selectedLanguage = this.computedDefaultLanguage
      }

      if (this.userData.phone_code) {
        this.selectedPhoneCode = this.searchPhoneCode(this.userData.phone_code)
      } else {
        this.selectedPhoneCode = this.defaultPhoneCode
      }

      if (this.employeeData.work_phone_code) {
        this.selectedWorkPhoneCode = this.searchPhoneCode(this.employeeData.work_phone_code)
      } else {
        this.selectedWorkPhoneCode = this.defaultPhoneCode
      }

      let userRole = roles.find(role => role.num === this.employeeData.role)
      if (userRole) {
        this.selectedRole = {...userRole, name: userRole.name[this.$i18n.locale]}
      }
    },
    visible() {
      if (!this.visible) {
        this.submitted = false
        this.savePassword = false
        this.selectedPhoneCode = null
        this.selectedWorkPhoneCode = null
        this.selectedRole = null
        this.selectedLanguage = null

        this.phoneNumberIsTaken = false
        this.takenPhoneNumber = null
        this.usernameIsTaken = false
        this.takenUsername = null
      }
    },
    '$i18n.locale'() {
      this.getRoles()
    }
  },
  mounted() {
    this.createPhoneCodesArray()
    // this.phoneCodes = phoneCodes
    this.getRoles()
  },
  methods: {
    checkTakenPhoneNumberMatches() {
      const code = this.selectedPhoneCode?.value
      const number = this.userData.phone_number

      if (!code || !number) return false

      const actualFullPhoneCode = code + number
      this.phoneNumberIsTaken = !!(actualFullPhoneCode === this.takenPhoneNumber)
    },
    checkTakenUsernameMatches() {
      this.usernameIsTaken = !!(this.userData.username === this.takenUsername)
    },
    checkBirthDateIsValid() {
      this.birthDateIsValid = this.checkDateIsValid(this.userData?.birthdate)
    },
    checkContractStartDateIsValid() {
      this.contractStartDateIsValid = this.checkDateIsValid(this.employeeData?.start)
    },
    checkContractEndDateIsValid() {
      this.contractEndDateIsValid = this.checkDateIsValid(this.employeeData?.end)
    },
    checkDateIsValid(date) {
      if (!date) return false
      return !!(date instanceof Date && !isNaN(date))
    },
    numberOnPaste(event) {
      const text = event.clipboardData.getData('text/plain')

      let isTextContainsSpace = false
      const splittedPhoneNumber = text.split('')
      splittedPhoneNumber?.forEach(n => {
        if (n === ' ') {
          isTextContainsSpace = true
        }
      })

      if (text.replace(/\s/g, '').length === 0 || isNaN(text) || isTextContainsSpace) {
        event.preventDefault()
        return false
      }
      this.checkTakenPhoneNumberMatches()
    },
    numberInputOnKeyPress(event) {
      const res = event.charCode >= 48 && event.charCode <= 57
      if (!res) {
        event.preventDefault()
        return false
      }
    },
    phoneNumberOnInput() {
      if (this.submitted) this.checkTakenPhoneNumberMatches()
    },
    // async findUserByPhoneNumber() {
    //   const code = this.selectedPhoneCode
    //   const number = this.userData.phone_number
    //   if (!code || !number) {
    //     return false
    //   }
    //
    //   const obj = {
    //     code,
    //     number
    //   }
    //
    //   try {
    //     const { status, data } = await httpClient.post('user/find-user-by-phone-number', obj)
    //     if (status === 200 && data) {
    //       console.log(status, data)
    //       this.disableSaveButton = true
    //     }
    //   } catch (err) {
    //     console.log(err)
    //   }
    // },
    createPhoneCodesArray() {
      this.phoneCodesArray = phoneCodes.map(code => {
        if (code[2] === settings.phoneCodes.default) {
          this.defaultPhoneCode = {
            value: code[2],
            country: code[0],
            flagCode: code[1]
          }
        }

        return {
          value: code[2],
          country: code[0],
          flagCode: code[1]
        }
      })
    },
    searchPhoneCode(phoneCode) {
      if (!phoneCode) return false
      let selectedPhoneCode = null

      for(let i = 0; i < phoneCodes.length; i++) {
        const code = phoneCodes[i]
        if (code[2] === phoneCode) {
          selectedPhoneCode = {
            value: code[2],
            country: code[0],
            flagCode: code[1]
          }
          break
        }
      }
      return selectedPhoneCode
    },
    getRoles() {
      const userRoles = roles.filter(role => role.num !== 1 && role.num !== 9 && role.num !== 11)
      if (userRoles?.length) {
        this.roleValues = userRoles.map(role => {
          return {
            num: role.num,
            name: role.name[this.$i18n.locale]
          }
        })
      }
    },
    async saveItem() {
      this.submitted = true
      if (!this.userData.first_name ||
          !this.selectedLanguage ||
          !this.userData.phone_number ||
          !this.selectedRole ||
          this.employeeData.start && this.employeeData.end && (this.employeeData.start > this.employeeData.end) ||
          (this.userData.email && !this.emailIsValid) ||
          (this.userData.birthdate && !this.birthDateIsValid) ||
          (this.employeeData.start && !this.contractStartDateIsValid) ||
          (this.employeeData.end && !this.contractEndDateIsValid) ||
          this.phoneNumberIsTaken || this.usernameIsTaken ||
          (this.employeeData.comment && this.employeeData.comment.length > this.commentMaxChars)) {
        return false
      }
      if (this.savePassword) {
        if (!this.userData.password ||
            !this.userData.password_repeat ||
            (this.userData.password !== this.userData.password_repeat) ||
            !this.userData.username) {
          return false
        }
        if (this.userData.password.length < 5) {
          console.log('Password length < 5')
          // return false
        }
        if (this.userData.username.length < 5) {
          console.log('Username length < 5')
          // return false
        }
      }

      this.disableSaveButton = true
      this.dataIsSending = true

      const user = {
        id: this.userData.id ?? null,
        first_name: this.userData.first_name ? this.userData.first_name.trim() : null,
        last_name: this.userData.last_name ? this.userData.last_name.trim() : null,
        email: this.userData.email ? this.userData.email.trim() : null,
        phone_code: this.selectedPhoneCode && this.userData.phone_number ? this.selectedPhoneCode.value : null,
        phone_number: this.selectedPhoneCode && this.userData.phone_number ? this.userData.phone_number.trim() : null,
        // full_phone_number: this.selectedPhoneCode && this.userData.phone_number ? this.userData.phone_number.trim() : null,
        // birthdate: this.userData.birthdate ? (Date.parse(this.userData.birthdate))/1000 : null,
        birthdate: this.userData.birthdate ? +new Date(this.userData.birthdate / 1000) : null,
        branch_id: this.$store.state.branchData.id,
        language: this.selectedLanguage.value,
        flag_code: this.selectedLanguage.flagCode,
        // address_id: this.userData.address ? this.userData.address.id : null,
        role: this.selectedRole.num,
        username: this.userData.username,
        user_address: this.userData.address
      }

      if (this.selectedPhoneCode && this.userData.phone_number) {
        user.full_phone_number = String(user.phone_code) + String(user.phone_number)
      }

      const employee = {
        id: this.userData.employee ? this.userData.employee.id : null,
        user_id: this.userData.id,
        position: this.employeeData.position ?? null,
        personal_code: this.employeeData.personal_code ?? null,
        contract: this.employeeData.contract ?? null,
        // start: this.employeeData.start ? (Date.parse(this.employeeData.start))/1000 : null,
        // end: this.employeeData.end ? (Date.parse(this.employeeData.end))/1000 : null,
        start: this.employeeData.start ? +new Date(this.employeeData.start / 1000) : null,
        end: this.employeeData.end ? +new Date(this.employeeData.end / 1000) : null,
        base_percent: this.employeeData.base_percent,
        work_phone_code: this.selectedWorkPhoneCode && this.employeeData.work_phone_number ? this.selectedWorkPhoneCode.value : null,
        work_phone_number: this.selectedWorkPhoneCode && this.employeeData.work_phone_number ? this.employeeData.work_phone_number : null,
        comment: this.employeeData.comment ? this.employeeData.comment : null,
      }

      if (employee.work_phone_code && employee.work_phone_number) {
        employee.full_work_phone_number = String(employee.work_phone_code) + String(employee.work_phone_number)
      }

      if (this.savePassword && this.userData.password) {
        user.password = this.userData.password.trim()
      }

      if (this.userData.id) {
        try {
          const { status, data } = await httpClient.post(`employee/update`, { user, employee })
          if (status === 200 && data?.success) {
            this.$emit('update-items', this.userData.id)
            this.$toast.add({severity: 'success', detail: this.$t('Data updated'), life: settings.toastLife});
            this.close()
          } else if (data?.error) {
            if (data.error.detail === 'Phone number has already been taken.') {
              this.$toast.add({severity:'error', summary: this.$t('Error'), detail: this.$t('Phone number has already been taken'), life: settings.toastLife})
              this.takenPhoneNumber = user.full_phone_number
              this.phoneNumberIsTaken = true
            // } else if (data.error.detail === 'Registration number has already been taken.') {
            //   this.$toast.add({severity:'error', summary: this.$t('Error'), detail: this.$t('Registration number has already been taken'), life: 1750})
            //   this.takenRegNumber = user.reg_number
            //   this.regNumberIsTaken = true
            } else if (data.error.detail === 'Username has already been taken.') {
              this.$toast.add({severity:'error', summary: this.$t('Error'), detail: this.$t('Username has already been taken'), life: settings.toastLife})
              this.takenUsername = user.username
              this.usernameIsTaken = true
            } else {
              this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: settings.toastLife});
            }
          }
        } catch(err) {
          this.showError(err)
        } finally {
          this.dataIsSending = false
          this.disableSaveButton = false
        }
      } else {
        user.status = 1
        try {
          const { status, data } = await httpClient.post(`employee/create`, { user, employee })
          if (status === 201 && data?.success) {
            this.userData.id = data.id
            user.id = data.id
            this.$emit('update-items', this.userData.id)
            this.$toast.add({severity:'success', detail: this.$t('Data saved'), life: 1750});
            this.close()
          } else if (data?.error) {
            if (data.error.detail === 'Phone number has already been taken.') {
              this.$toast.add({severity:'error', summary: this.$t('Error'), detail: this.$t('Phone number has already been taken'), life: 1750})
              this.takenPhoneNumber = user.full_phone_number
              this.phoneNumberIsTaken = true
            // } else if (data.error.detail === 'Registration number has already been taken.') {
            //   this.$toast.add({severity:'error', summary: this.$t('Error'), detail: this.$t('Registration number has already been taken'), life: 1750})
            //   this.takenRegNumber = user.reg_number
            //   this.regNumberIsTaken = true
            } else if (data.error.detail === 'Username has already been taken.') {
              this.$toast.add({severity:'error', summary: this.$t('Error'), detail: this.$t('Username has already been taken'), life: 1750})
              this.takenUsername = user.username
              this.usernameIsTaken = true
            } else {
              this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
            }
          }
        } catch(err) {
          this.showError(err)
        } finally {
          this.dataIsSending = false
          this.disableSaveButton = false
        }
      }
    },
    close() {
      this.$emit('close')
      this.submitted = false
    },
    // updateAddress(newAddress, userIsNotCreatedYet = false) {
    //   this.userData.address = { ...newAddress }
    //   if (!userIsNotCreatedYet) {
    //     this.$emit('update-items', this.userData.id)
    //   }
    // },
    updateAddress(newAddress) {
      this.userData.address = { ...newAddress }
      this.$emit('update-item', this.userData)
    },
    updateItems() {
      this.$emit('update-items', this.userData.id)
    },
    // addAddress() {
    //   this.userAddress = {}
    //   this.addressModal = true
    //   if (this.$store.state.secondLayerIsOpened === true ) {
    //     this.$store.commit('toggleSecondLayer', false)
    //   }
    // },
    // editAddress(userAddress) {
    //   this.userAddress = { ...userAddress }
    //   this.userData.address = { ...userAddress }
    //   this.addressModal = true
    //   if (this.$store.state.secondLayerIsOpened === true ) {
    //     this.$store.commit('toggleSecondLayer', false)
    //   }
    // },
    // async changeAddress(newAddress, draft = false) {
    //   this.companyAddress = { ...newAddress }
    //   if (draft) {
    //     this.addressDraft = { ...newAddress }
    //   }
    //   this.userData.address = { ...newAddress }
    //   this.$emit('update-items', this.userData.id)
    // },
    validateEmail() {
      const email = this.userData?.email
      if (!email) return false

      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.emailIsValid = re.test(email)
    }
  },
  computed: {
    computedWindowSize() {
      if (this.$store.state.mobileLayout) {
        return {position: 'absolute', top: 0, width: '95%', marginTop: '10px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '550px', marginTop: '15px'}
      } else {
        return {position: 'absolute', top: 0, width: '550px'}
      }
    },
    computedDefaultLanguage() {
      return settings.languages.find(language => language.isDefault) ?? null
    },
    deleteAddressData() {
      if (this.userData.address) {
        return (this.userData.address.address ? this.userData.address.address : '-') +
            (this.userData.address.address2 ? ', ' + this.userData.address.address2 : '') +
            (this.userData.address.postcode ? ', ' + this.userData.address.postcode : '') +
            (this.userData.address.city ? ', ' + this.userData.address.city : '') +
            (this.userData.address.state ? ', ' + this.userData.address.state: '') +
            (this.userData.address.country ? ', ' + this.userData.address.country.name : '')
      } else {
        return null
      }
    },
  },

}
</script>

<style scoped lang="scss">
.p-button-text {
  width: auto;
}

</style>